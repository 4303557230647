import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import { Layout } from "../components/layout"
import { parseDate, formatDateInterval } from "../data/utils"
import { Session } from "../data/detection"

interface Props {
  pathContext: {
    session: Session
  }
}

const iframeStyle = {
  width: "100%",
  height: "750px",
  border: "none",
}

const propulsedStyle = {
  width: "100%",
  textAlign: "center" as const,
}

const ReservationDetection: React.FC<Props> = ({ pathContext }) => (
  <Layout>
    <Helmet title={`${pathContext.session.name} - Réservation - Stage Détection`} />
    <div className="container mt-3">
      <h1>
        {pathContext.session.name}
        <br />
        {pathContext.session.dateStart && pathContext.session.dateEnd ? <small>{formatDateInterval(parseDate(pathContext.session.dateStart), parseDate(pathContext.session.dateEnd))}</small> : <small>{pathContext.session.dateStart}</small>}
      </h1>
      <p>
        3 étapes pour s&apos;inscrire, si vous avez déjà effectué les deux premières pour une des sessions, il n&apos;est pas nécessaire de
        les recommencer.
      </p>
      <div className="card-deck my-3">
        <div className="card">
          <div className="card-body">
            <p className="card-text">
              Télécharger et remplir l&apos;
              <b>autorisation parentale</b> et la <b>fiche sanitaire de liaison</b>.
            </p>
            <a
              href="/formations/fiches-stages-detection.zip"
              className="btn btn-outline-secondary"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="icon-download" /> Télécharger
            </a>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <p className="card-text">
              Envoyer les deux fichiers <b>autorisation parentale</b> et la <b>fiche sanitaire de liaison</b>.
            </p>
            <a
              href="https://cloud.tiralarc-grand-est.fr/s/ibzCGB6xDQDsbyF"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-outline-secondary"
            >
              <span className="icon-upload" /> Envoyer
            </a>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <p className="card-text">
              <b>S&apos;inscrire et payer</b> grâce au formulaire ci-dessous à la session :
              <br />
              <h4 className="mt-2">{pathContext.session.name}</h4>
              ou <Link to="/formations/detection-2021-2022/">choisir une autre session.</Link>
            </p>
          </div>
        </div>
      </div>
      {pathContext.session.shopId != null ? (
        <>
          <iframe
            id="haWidget"
            title="Paiement formation"
            allowTransparency
            scrolling="auto"
            src={`https://www.helloasso.com/associations/comite-regional-de-tir-a-l-arc-du-grand-est/evenements/${pathContext.session.shopId}/widget`}
            style={iframeStyle}
          />
          <div style={propulsedStyle}>
            Propulsé par{" "}
            <a href="https://www.helloasso.com" rel="nofollow">
              HelloAsso
            </a>
          </div>
        </>
      ) : (
        <div className="card my-3">
          <div className="card-body">
            <p className="card-text">
              Le formulaire de paiement sera prochainement disponible.
            </p>
          </div>
        </div>
      )}
    </div>
  </Layout>
)

export default ReservationDetection
